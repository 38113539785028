/**
 * HeroSlider
 */

import React, {useState} from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import Slider from 'react-slick';
import _ from 'lodash';
import Breadcrumbs from 'Components/Breadcrumbs';
import ButtonLink from 'Components/ButtonLink';
import Image from 'Components/Image';
import Video from 'Components/Video';
import { useStyle } from 'Themes/theme';

import sD from './HeroSlider.Dansskolanentre.module.scss';
import sK from './HeroSlider.Kulturama.module.scss';
import sM from './HeroSlider.Medborgarskolan.module.scss';
import sP from './HeroSlider.Pahlmans.module.scss';
import sT from './HeroSlider.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const HeroSlider = ({
    items = [],
    breadcrumbs = {},
    defaultItem = {},
    modifier = '',
    text = '',
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const s = useStyle(styles);

    const afterChangeHandler = (index) => {
        if(index !== currentSlide) {
            setCurrentSlide(index);
        }
    };

    const isDefault = items.length <= 0;
    const isSingle = items.length <= 1;
    const isMore = items.length > 1;

    let sliderItems = items;
    if(sliderItems.length <= 0) {
        if(breadcrumbs && breadcrumbs.items) {
            defaultItem['breadcrumbs'] = breadcrumbs;
        }
        sliderItems = [defaultItem];
    }

    let image = {};
    if(sliderItems[0] && sliderItems[0].image && sliderItems[0].image.src) {
        image = sliderItems[0].image;
    }

    const sliderSettings = {
        infinite: isMore,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: isMore,
        dots: isMore,
        autoplay: isMore,
        autoplaySpeed: 2000,
        pauseOnFocus: false,
        pauseOnHover: false,
        nextArrow: <Arrow type='next' />,
        prevArrow: <Arrow type='prev' />,
    };

    const classes = classNames(
        s['HeroSlider'],
        {[s[`HeroSlider--${modifier}`]]: modifier},
        {[s['HeroSlider--Default']]: isDefault},
        {[s['HeroSlider--Single']]: isSingle},
        {[s['HeroSlider--More']]: isMore},
    );

    return (
        <div className={classes}>
            <div className={s['HeroSlider__DefaultBackground']}>
                <Image
                    {...image}
                    sizes={['100vw']}
                    lazyLoad={false}
                    useCover={true}
                />
            </div>

            <Slider
                {...sliderSettings}
                className={s['HeroSlider__List']}
                afterChange={(index) => afterChangeHandler(index)}
            >
                {sliderItems.map((item, index) => (
                    <div className={s['HeroSlider__Item']} key={index}>
                        <Slide
                            {...item}
                            index={index}
                            isActive={currentSlide === index}
                        />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

const Slide = ({
    index,
    breadcrumbs,
    label,
    title,
    text,
    image,
    video,
    link,
    isActive,
}) => {
    const [showVideo, setShowVideo] = useState(false);

    const s = useStyle(styles);

    const handleShowVideo = () => {
        if(!showVideo) {
            setShowVideo(true);
        }
    }

    const classes = classNames(
        s['HeroSlider__Slide'],
        {[s['HeroSlider__Slide--Video']]: showVideo},
    );

    return (
        <div className={classes}>
            {video &&
                <Video
                    showAsBackground={true}
                    url={video}
                    showVideo={() => handleShowVideo()}
                    active={isActive}
                    playerProps={{
                        quality: '4K',
                    }}
                />
            }

            <div className={s['HeroSlider__Background']}>
                <Image
                    {...image}
                    sizes={['100vw']}
                    lazyLoad={index > 0}
                    useCover={true}
                />
            </div>

            <div className={s['HeroSlider__Container']}>
                <div className={s['HeroSlider__TextContainer']}>
                    {breadcrumbs &&
                        <Breadcrumbs {...breadcrumbs} />
                    }

                    {label &&
                        <div
                            className={s['HeroSlider__Label']}
                            dangerouslySetInnerHTML={{__html: label}}
                        />
                    }

                    {index === 0 &&
                        <h1
                            id="h1id"
                            className={s['HeroSlider__Title']}
                            dangerouslySetInnerHTML={{__html: title}}
                        />
                    }

                    {index > 0 &&
                        <h2
                            className={s['HeroSlider__Title']}
                            dangerouslySetInnerHTML={{__html: title}}
                        />
                    }

                    {text &&
                        <div className={s['HeroSlider__Text']}>{text}</div>
                    }

                    {link && link.text && link.href &&
                        <ButtonLink
                            {...link}
                            componentClass={s['HeroSlider__Button']}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

const Arrow = ({type, onClick}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const label = t(`heroSlider.labels.${type}`);

    const classes = classNames(
        s['HeroSlider__Arrow'],
        [s[`HeroSlider__Arrow--${_.upperFirst(type)}`]],
        {[s['HeroSlider__Arrow--Disabled']]: !onClick},
    );

    return (
        <button
            className={classes}
            onClick={onClick}
        >
            <span className={s['HeroSlider__ArrowIcon']} />
            <span className="sr-only">{label}</span>
        </button>
    );
};

export default HeroSlider;
