/**
 * Notification
 */

import React, { useContext, useEffect } from 'react';
import BaseContext from 'Layouts/BasePage/BaseContext';
import { setCookie, getCookie } from '../../utils/Cookie';
import Arrow from '../../public/inline-svg/arrow.svg';
import ArrowKulturama from '../../public/inline-svg/arrow-kulturama.svg';
import ArrowTillskarar from '../../public/inline-svg/arrow-tillskarar.svg';
import ArrowMedborgarskolan from '../../public/inline-svg/arrow-medborgarskolan-thin.svg';
import Wysiwyg from 'Components/Wysiwyg';
import { useStyle } from 'Themes/theme';

import sD from './Notification.Dansskolanentre.module.scss';
import sK from './Notification.Kulturama.module.scss';
import sM from './Notification.Medborgarskolan.module.scss';
import sP from './Notification.Pahlmans.module.scss';
import sT from './Notification.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Notification = ({ cookie = {}, wysiwyg = '', href = '' }) => {
    const s = useStyle(styles);

    const { currentTheme } = useContext(BaseContext);

    useEffect(() => {
        // Set cookie if param is in url
        if (cookie) {
            const { domain, name } = cookie;

            const hasCookie = getCookie(name) === 'true';
            if (!hasCookie) {
                let search = window.location.search;
                if (search.startsWith('?')) {
                    search = search.substr(1);
                }
                const parts = search.split('&');

                parts.forEach(function (key) {
                    if (key.startsWith(name)) {
                        setCookie(name, true, 30, domain);
                    }
                });
            }
        }
    }, []);

    const clickHandler = (e) => {
        if (cookie) {
            e.preventDefault();

            const { domain, name } = cookie;
            setCookie(name, true, 30, domain);

            window.location.href = href;
        }
    };

    return (
        <div className={s['Notification']}>
            {href && (
                <a
                    href={href}
                    className={s['Notification__Link']}
                    onClick={(e) => clickHandler(e)}
                >
                    <div className={s['Notification__Container']}>
                        <div className={s['Notification__Content']}>
                            <IconArrow theme={currentTheme} />
                            <Wysiwyg wysiwyg={wysiwyg} />
                        </div>
                    </div>
                </a>
            )}
            {!href && (
                <div className={s['Notification__Container']}>
                    <div className={s['Notification__Content']}>
                        <IconArrow theme={currentTheme} />
                        <Wysiwyg wysiwyg={wysiwyg} />
                    </div>
                </div>
            )}
        </div>
    );
};

const IconArrow = ({ theme }) => {
    const icons = {
        Kulturama: <ArrowKulturama />,
        Tillskararakademin: <ArrowTillskarar />,
        Medborgarskolan: <ArrowMedborgarskolan />,
    };
    return icons[theme] || <Arrow />;
};

export default Notification;
