/* global isJestTest */
/**
 * Video
 */

import React, {useEffect, useState, useRef, useContext, Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Vimeo from '@vimeo/player';
import ReactPlayer from 'react-player';
import { isCookieAllowed } from 'utils/Cookie';
import BaseContext from 'Layouts/BasePage/BaseContext';
import VideoMessage from './VideoMessage';
import { useStyle } from 'Themes/theme';

import sD from './Video.Dansskolanentre.module.scss';
import sK from './Video.Kulturama.module.scss';
import sM from './Video.Medborgarskolan.module.scss';
import sP from './Video.Pahlmans.module.scss';
import sT from './Video.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Video = ({
    url = '',
    active = true, // Initial state
    showVideo = () => {},
    pauseVideo = false,
    readyVideo = () => {},
    playingVideo = () => {},
    autoPlay = true,
    playerProps = {},
    useVideoMessage = false,
    showAsBackground = true,
}) => {
    const {isSSR, cookiePopup} = useContext(BaseContext);

    const s = useStyle(styles);

    const isTest = typeof isJestTest !== 'undefined' && isJestTest;
    const isVimeo = url.includes('vimeo');

    const videoRef = useRef();

    const defaultConsented = isCookieAllowed(cookiePopup, 'YSC');
    const [consented, setConsented] = useState(defaultConsented);

    const [ready, setReady] = useState(false);
    const [startPlaying, setStartPlaying] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [propSet, setPropSet] = useState(false);
    const [portraitMode, setPortraitMode] = useState(false);
    const [height, setHeight] = useState(null);
    const [width, setWidth] = useState(null);

    const [loadYoutube, setLoadYoutube] = useState(false);
    const [showVideoAfterMessage, setShowVideoAfterMessage] = useState(false);

    useEffect(() => {
        setMounted(true);

        window.addEventListener('resize', () => {
            reloadVideoProp(true);
        });

        document.addEventListener('cookie-popup-changed', () => {
            setConsented(isCookieAllowed(cookiePopup, 'YSC'));
        });
    }, []);

    useEffect(() => {
        if(active && startPlaying) {
            reloadVideoProp();

            if(!mounted) {
                setMounted(true);
            }
        }
    }, [active, startPlaying]);

    useEffect(() => {
        if(!isSSR && !loadYoutube) {
            setLoadYoutube(true);
        }
    }, [isSSR, loadYoutube]);

    const readyHandler = () => {
        if(!ready) {
            setReady(true);
            readyVideo(true);
        }
    };

    const playingHandler = () => {
        if(!startPlaying) {
            setStartPlaying(true);
            playingVideo(true);
        }
    };

    // Change default settings depending on if video is background or regular player
    const videoProps = Object.assign({
        playing: showAsBackground,
        loop: showAsBackground,
        muted: showAsBackground,
        controls: !showAsBackground,
        playsInline: showAsBackground,
        background: showAsBackground.toString(),
        ...(showAsBackground && {volume: 0}),
    }, playerProps);

    const reloadVideoProp = (forceReload = false) => {
        if((!active || !startPlaying) && !forceReload) {
            return false;
        }

        const wrapper = videoRef.current;
        if(!wrapper) {
            return false;
        }

        const prop = (wrapper.offsetHeight / wrapper.offsetWidth).toPrecision(4);

        let newHeight = height;
        let newWidth = width;

        // Only set height and width the first time
        if(newHeight === null || newWidth === null) {
            const iframe = wrapper.querySelector('iframe');
            if(iframe.width && iframe.width !== '100%' && iframe.height && iframe.height !== '100%') {
                newHeight = iframe.height;
                newWidth = iframe.width;
            } else {
                newHeight = iframe.offsetHeight;
                newWidth = iframe.offsetWidth;
            }
            setHeight(newHeight);
            setWidth(newWidth);
        }

        // Compare iframe to player, and change width or height depending on proportion
        const newPortraitMode = (newWidth * prop) > newHeight;
        if(!propSet || newPortraitMode !== portraitMode) {
            wrapper.style.opacity = 0;

            // Tell container that video is ready
            if(!propSet) {
                // If video is muted and background, add extra time for it to really by ready
                if(videoProps.muted === true && videoProps.background === true) {
                    setTimeout(() => {
                        showVideo();
                    }, 1000);
                } else {
                    showVideo();
                }
            }

            // If calculated diff is more than iframe height, increase the width for portrait
            setPropSet(true);
            setPortraitMode(newPortraitMode);
        }
    };

    // Show video message for youtube after ssr loaded and user has not consented in youtube cookies.
    // useVideoMessage is default false so video isn't displayed when consented, specifically
    // because video is mostly used as background, if videoPlayer is used the message will be shown
    const showVideoMessage = !isVimeo && loadYoutube && !consented && useVideoMessage;

    // Show player when mounted and either is vimeo or has consented or after video message
    const showPlayer = mounted && (isVimeo || consented || showVideoAfterMessage);

    const classes = classNames(
        s['Video'],
        {[s['Video--Loaded']]: showVideoMessage || (showVideo && ready && startPlaying && propSet) || !autoPlay},
        {[s['Video--HasProportion']]: propSet || !autoPlay},
        {[s['Video--Portrait']]: portraitMode && showAsBackground},
        {[s['Video--Landscape']]: !portraitMode && showAsBackground},
        {[s['Video--Cover']]: showAsBackground},
        {[s['Video--Contain']]: !showAsBackground},
    );

    if(isTest) {
        return null;
    }

    const Component = isVimeo ? VimeoPlayer : YouTubePlayer;
    const player = (
        <Component
            className={s['Video__Player']}
            props={videoProps}
            url={url}
            readyHandler={readyHandler}
            playingHandler={playingHandler}
            pauseVideo={pauseVideo}
            startPlaying={startPlaying}
            autoPlay={autoPlay}
        />
    );
    return (
        <div className={classes} ref={videoRef}>
            {showPlayer &&
                <Fragment>{player}</Fragment>
            }

            {showVideoMessage &&
                <div className={s['Video__VideoMessage']}>
                    <VideoMessage
                        src={url}
                        iframe={player}
                        showVideo={() => setShowVideoAfterMessage(true)}
                    />
                </div>
            }
        </div>
    );
};

Video.propTypes = {
    url: PropTypes.string,
    active: PropTypes.bool,
    showVideo: PropTypes.func,
    pauseVideo: PropTypes.bool,
    readyVideo: PropTypes.func,
    playingVideo: PropTypes.func,
    autoPlay: PropTypes.bool,
    playerProps: PropTypes.object,
    useVideoMessage: PropTypes.bool,
    showAsBackground: PropTypes.bool,
};

const VimeoPlayer = ({
    props = {},
    url = '',
    readyHandler = null,
    playingHandler = null,
    pauseVideo = false,
    autoPlay = true,
}) => {
    const s = useStyle(styles);

    const vimeoRef = useRef();
    const {cookiePopup} = useContext(BaseContext);

    const defaultConsented = isCookieAllowed(cookiePopup, 'vuid');
    const [consented, setConsented] = useState(defaultConsented);

    const [vimeo, setVimeo] = useState(null);

    useEffect(() => {
        document.addEventListener('cookie-popup-changed', () => {
            setConsented(isCookieAllowed(cookiePopup, 'vuid'));
        });
    }, []);

    useEffect(() => {
        const options = Object.assign(props, {
            url: url,
            dnt: !consented,
            autoplay: autoPlay,
            autopause: true,
            showTitle: false,
            showByline: false,
        });

        if(vimeoRef && vimeoRef.current) {
            const player = new Vimeo(vimeoRef.current, options);
            player.ready().then(() => {
                readyHandler();
                setVimeo(player);
                player.on('bufferend', playingHandler);
            });
        }
    }, []);

    useEffect(() => {
        if(vimeo !== null) {
            if(pauseVideo) {
                vimeo.pause();
            } else {
                vimeo.play();
            }
        }
    }, [pauseVideo, vimeo]);

    return (
        <div
            className={classNames(s['Video__Player'], s['Video__Player--Vimeo'])}
            ref={vimeoRef}
        />
    );
};

VimeoPlayer.propTypes = {
    props: PropTypes.object,
    url: PropTypes.string,
    readyHandler: PropTypes.func,
    playingHandler: PropTypes.func,
    pauseVideo: PropTypes.bool,
    autoPlay: PropTypes.bool,
};

const YouTubePlayer = ({
    props = {},
    url = '',
    readyHandler = null,
    playingHandler = null,
    pauseVideo = false,
    startPlaying = false,
}) => {
    const s = useStyle(styles);

    if(startPlaying && pauseVideo) {
        props.playing = false;
    }

    // TODO: Find a different player than react-player since it blinks on loop
    const config = {
        youtube: {
            playerVars: {
                disablekb: 1,
                modestBranding: 1,
                rel: 0,
            }
        }
    };
    return (
        <ReactPlayer
            {...props}
            config={config}
            className={classNames(s['Video__Player'], s['Video__Player--YouTube'])}
            url={url}
            onReady={readyHandler}
            onBufferEnd={playingHandler}
        />
    );
};

YouTubePlayer.propTypes = {
    props: PropTypes.object,
    url: PropTypes.string,
    readyHandler: PropTypes.func,
    playingHandler: PropTypes.func,
    pauseVideo: PropTypes.bool,
    startPlaying: PropTypes.bool,
};

export default Video;
