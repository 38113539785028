/**
 * CardArticle
 */

import React from 'react';
import classNames from 'classnames';
import SanitizeHtml from 'sanitize-html-react';
import Image from 'Components/Image';
import { useStyle } from 'Themes/theme';

import sD from './CardArticle.Dansskolanentre.module.scss';
import sK from './CardArticle.Kulturama.module.scss';
import sM from './CardArticle.Medborgarskolan.module.scss';
import sP from './CardArticle.Pahlmans.module.scss';
import sT from './CardArticle.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const CardArticle = ({ tag, title, text, image, link, imageSizes }) => {
    const sanitizedTitle = SanitizeHtml(title);

    const s = useStyle(styles);

    const classes = classNames(
        s['CardArticle'],
        { [s['CardArticle--NoImage']]: !image.src }
    );

    return (
        <article className={classes}>
            <a className={s['CardArticle__Link']} href={link.href}>
                <span
                    className="sr-only"
                    dangerouslySetInnerHTML={{ __html: link.text }}
                />
            </a>
            <div className={s['CardArticle__Container']}>
                {image && image.src &&
                    <div className={s['CardArticle__ImageContainer']}>
                        <Image
                            {...image}
                            className={s['CardArticle__Image']}
                            sizes={imageSizes}
                            useCover={true}
                        />
                    </div>
                }
                <div className={s['CardArticle__Content']}>
                    <div className={s['CardArticle__Tag']}>{tag}</div>
                    <h3
                        className={s['CardArticle__Title']}
                        dangerouslySetInnerHTML={{ __html: sanitizedTitle }}
                    />
                    <p className={s['CardArticle__Text']}>{text}</p>
                    <div
                        className={s['CardArticle__ReadMore']}
                        aria-hidden="true"
                        dangerouslySetInnerHTML={{ __html: link.text }}
                    />
                </div>
            </div>
        </article>
    );
};

CardArticle.defaultProps = {
    tag: '',
    title: '',
    text: '',
    image: {},
    link: {},
    imageSizes: [],
};

export default CardArticle;
